<template>
	<v-sheet id="contact" color="#fff" light tag="section" tile>
		<div class="py-12"></div>

		<v-container>
			<h2
				class="font-weight-bold mb-3 text-uppercase text-center"
				:class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
			>
				{{ $t('contactUs') }}
			</h2>

			<v-responsive class="mx-auto mb-12" width="56">
				<v-divider class="mb-1"></v-divider>
				<v-divider></v-divider>
			</v-responsive>

			<v-theme-provider light>
				<v-form ref="form" enctype="multipart/form-data">
					<v-row>
						<!-- <v-col cols="12">
							<v-text-field
								v-model="name"
								:label="$t('name') + '*'"
								:rules="nameRules"
								required
							></v-text-field>
						</v-col> -->

						<v-col cols="12">
							<v-text-field
								:label="$t('email') + '*'"
								name="email"
								type="email"
								v-model="email"
								:rules="emailRules"
								required
							></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field
								v-model="subject"
								:label="$t('subject') + '*'"
								:rules="subjectRules"
								required
							></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-textarea
								id="message"
								:label="$t('message') + '*'"
								name="message"
								type="text"
								v-model="message"
								rows="2"
								auto-grow
								:rules="messageRules"
								required
							></v-textarea>
						</v-col>

						<!-- Loader -->
						<v-col cols="12" align="center">
							<v-progress-circular
								v-if="isLoading"
								:width="3"
								color="green"
								indeterminate
								class="text-center"
							></v-progress-circular>
						</v-col>

						<v-col class="mx-auto" cols="auto">
							<v-btn color="secondary" x-large @click="onSubmit">
								{{ $t('submit') }}
							</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</v-theme-provider>
		</v-container>

		<div class="py-6"></div>
	</v-sheet>
</template>

<script>
export default {
	name: 'AppContacts',

	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			isLoading: false,
			name: 'user',
			subject: '',
			email: '',
			message: '',
			nameRules: [v => !!v || this.$i18n.t('requiredField')],
			subjectRules: [v => !!v || this.$i18n.t('requiredField')],
			emailRules: [
				v => !!v || this.$i18n.t('requiredField'),
				v => /.+@.+\..+/.test(v) || this.$i18n.t('validEmail'),
			],
			messageRules: [v => !!v || this.$i18n.t('requiredField')],
		};
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		async onSubmit() {
			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			this.isLoading = true;

			const payload = {
				name: this.name,
				subject: this.subject,
				email: this.email,
				message: this.message,
			};

			try {
				const res = await this.$http.post(`${this.url}/contact`, payload);

				this.name = '';
				this.subject = '';
				this.email = '';
				this.message = '';
				this.isLoading = false;

				this.$refs.form.resetValidation();
			} catch (error) {
				this.isLoading = false;
				console.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
p {
	margin-bottom: 0px !important;
	color: #f0ae1d;
}
</style>
